<template>
    <vue-easy-lightbox :moveDisabled='true' :visible="visible" :imgs="photo" :index="index" @hide="handleHide">
        <template v-if="false" v-slot:prev-btn="{ prev }">
            <div @click="prev" class="btn__prev">
            <svg class="carousel__icon" viewBox="0 0 24 24" role="img">
                <title>arrowLeft</title>
                <path d="M16.3,6.3L10.8,12l5.5,5.6c0.6,0.6,0.6,1.4,0,2l0,0c-0.6,0.6-1.4,0.6-2,0L7.7,13c-0.6-0.6-0.6-1.4,0-2l6.6-6.6
        c0.6-0.6,1.4-0.6,2,0l0,0C16.9,4.9,16.9,5.8,16.3,6.3z"/>
            </svg>
            </div>
        </template>
        <template v-if="false" v-slot:next-btn="{ next }">
            <div @click="next" class="btn__next">
            <svg class="carousel__icon" viewBox="0 0 24 24" role="img">
                <title>arrowRight</title>
                <path d="M8,17.6l5.5-5.6L8,6.4c-0.6-0.6-0.6-1.4,0-2l0,0c0.6-0.6,1.4-0.6,2,0l6.6,6.6c0.6,0.6,0.6,1.4,0,2L10,19.5
        c-0.6,0.6-1.4,0.6-2,0l0,0C7.4,19,7.4,18.2,8,17.6z"/>
            </svg>
            </div>
        </template>
        <template v-slot:close-btn="{ close }">
            <button @click="close" class="close position-absolute m-0 p-0"></button>
        </template>
    </vue-easy-lightbox>

    <div class="awards row justify-content-center">
        <!-- 返回頂部 -->
        <div class="goTop">
            <img src="@/assets/images/btn_gotop.png" alt="" class="w-100">
        </div> 
        
        <!-- [Left] fixd logo  -->
        <transition name="fade">
            <div v-show="$route.name!='index' && delay" class="page_kv_fixed">
            <img src="@/assets/images/img_kv_head_page.png" class="w-100" alt="">
            </div>
        </transition>

        <!-- loading -->
        <PagesLoading v-show="isPagesLoading"/>

        <!-- Filter -->
        <div class="subNav col-xl-10 flex-column flex-sm-row justify-content-between mb-3 mb-md-3 mt-md-1" v-show="!isPagesLoading" ref="clickaway">
            <div class="subNav__left d-flex text-white order-12 order-sm-1">
                <!-- select 1 -->
                <ul class="list-unstyled text-white mb-0">
                    <li @click="showToggle = !showToggle">
                        <button class="btn btn-danger dropdown font-weight-bold text-left text-white" :class="{'dropdown--active':showToggle}">
                            <span>{{defaultItem}}</span>
                            <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                            <polygon points="11.63 5.19 8.01 8.44 4.37 5.18 3.2 6.51 8.01 10.81 12.8 6.51 11.63 5.19"/>
                            </svg>
                        </button>
                    </li>
                    <transition name="toggle">
                        <li v-if="showToggle" class="dropdown__item">
                            <ul class="list-unstyled">
                                <li v-if="defaultItem!='全部組別'" @click="defaultItem ='全部組別'; ReadData(); showToggle = false">全部組別</li>
                                <li @click="defaultItem = '社會組'; ReadData('社會組'); showToggle = false" >社會組</li>
                                <li @click="defaultItem = '學生組'; ReadData('學生組'); showToggle = false" >學生組</li>
                            </ul>
                        </li>
                    </transition>
                </ul>
            </div>
        </div>

        <div class="col-lg-10 col-xl-7">
             <!-- 大卡片 -->
            <div class="position-relative card__large" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
                <div class="carousel-wrap w-100" ref="carousel" :style="deviceMode== 'mobile'?'min-height:auto' : 'min-height:' + minHeightL">
                    <keep-alive>
                        <div v-for="(s, i) in Collection_cardLarge" :key="i" class="item" :class="{ 'position-relative': show_cardLarge == i }">
                            <transition :name="carouselName">
                                <span v-if="deviceMode == 'mobile' ? true : show_cardLarge == i" class="flex-fill">
                                    <div :id="s.ID" class="card pt-1 pb-3 mb-4 mb-md-0" :v-model="nowPhoto = s.ID">
                                        <div class="row justify-content-center">
                                            <div class="col-12 card__info d-flex align-items-center mb-1">
                                                <img v-if="s.Ranking == '1'" class="my-2 my-md-1 mr-1 mr-md-2" src="@/assets/images/photography/img_ranking1_L.png">
                                                <img v-if="s.Ranking == '2'" class="my-2 my-md-1 mr-1 mr-md-2" src="@/assets/images/photography/img_ranking2_L.png">
                                                <img v-if="s.Ranking == '3'" class="my-2 my-md-1 mr-1 mr-md-2" src="@/assets/images/photography/img_ranking3_L.png">
                                                <img v-if="s.Ranking == '999'" class="my-2 my-md-1 mr-1 mr-md-2" src="@/assets/images/photography/img_ranking999_L.png">
                                                <div class="d-flex flex-column flex-md-row flex-grow-1 justify-content-md-between">
                                                    <h3 v-if="s.Ranking == '1'" class="awards__title mb-0">金獎－{{s.Title}}</h3>
                                                    <h3 v-if="s.Ranking == '2'" class="awards__title mb-0">銀獎－{{s.Title}}</h3>
                                                    <h3 v-if="s.Ranking == '3'" class="awards__title mb-0">銅獎－{{s.Title}}</h3>
                                                    <h3 v-if="s.Ranking == '999'" class="awards__title mb-0">佳作－{{s.Title}}</h3>
                                                    <!-- icons -->
                                                    <ul class="d-flex list-unstyled ml-md-auto mb-0">
                                                        <li>
                                                            <a target="_blank" :href="s.GoogleMapUrl" class="icon icon--map"></a>
                                                        </li>
                                                        <li class="ml-2">
                                                            <a target="_blank" :href="'https://facebook.com/sharer/sharer.php?u=https://www.2021tamsui.tw/awards/'+ s.ID + '/f'" class="icon icon--fb"></a>
                                                        </li>
                                                        <li class="ml-2">
                                                            <a target="_blank" :href="'https://social-plugins.line.me/lineit/share?url=https://www.2021tamsui.tw/awards/'+ s.ID + '/l'" class="icon icon--line"></a>
                                                        </li>
                                                        <li class="ml-2">
                                                            <span class="icon icon--like" @click="ClickNum(s.ID)" :class="{'active': isPhotoLiked(s.ID) || s.ID == photoLiked}"></span>
                                                            <small class="font-weight-bold text-danger ml-1">{{parseInt(s.CKNum) + 1}}</small>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-12 mb-3">
                                                <div class="card__photo carousel__slide text-center">
                                                    <img :src="s.Img" alt="" @click="showImg(i); photo = s.Img">
                                                </div>
                                            </div>
                                            <div class="col-12 d-md-flex">
                                                <div v-if="deviceMode != 'mobile'" class="awards__author mr-3">
                                                    <div class="awards__headshot ml-auto mx-lg-auto">
                                                        <div v-if="s.AuthorImg!=''" :style="'background-image:url('+ s.AuthorImg +')'"></div>
                                                        <div v-else style="background-image:url('https://api.2021tamsui.tw/Files/author.png')"></div> 
                                                    </div>
                                                    <span class="font-weight-bold">{{s.Name}}</span>
                                                </div>
                                                <div class="awards__des">
                                                    <div v-if="deviceMode == 'mobile'" class="awards__author mr-2">
                                                        <div class="awards__headshot ml-auto mx-lg-auto">
                                                            <div v-if="s.AuthorImg!=''" :style="'background-image:url('+ s.AuthorImg +')'"></div>
                                                            <div v-else style="background-image:url('https://api.2021tamsui.tw/Files/author.png')"></div>    
                                                        </div>
                                                    </div>
                                                    <span v-if="deviceMode == 'mobile'" class="font-weight-bold">{{s.Name}}</span>
                                                    <p class="mb-0">{{s.Info}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </transition>
                        </div>
                    </keep-alive>
                    <!-- <div class="dot-group">
                        <a v-for="(l, i) in len" href="#" :class="{ 'active': show == i }" @click.prevent="show = i" :key="i"></a>
                    </div> -->
                </div>
                 <button class="carousel__prev" @click.prevent="toPrev" v-show="!isPagesLoading && deviceMode != 'mobile'">
                    <svg class="carousel__icon" viewBox="0 0 24 24" role="img">
                        <title>arrowLeft</title>
                        <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
                    </svg>
                </button>
                <button class="carousel__next" @click.prevent="toNext" v-show="!isPagesLoading && deviceMode != 'mobile'">
                    <svg class="carousel__icon" viewBox="0 0 24 24" role="img">
                        <title>arrowRight</title>
                        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
                    </svg>
                </button>
            </div>
            <!-- 小卡片 -->
            <div v-if="deviceMode != 'mobile'" class="card__small position-relative mt-4" :style="'min-height:' + minHeightS" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
                <div v-for="(d, i) in Collection_cardSmall" :key="i" class="item" :class="{ 'position-relative': show_cardSmall == i }">
                    <transition :name="carouselName">
                        <div v-if="show_cardSmall == i" class="row">
                            <div v-for="(dd, ii) in Collection_cardSmall[i]" :key="ii" class="card__wrap col-4 px-2" @click="getPhotoId(dd.ID)">
                                <div class="card p-2" :class="{'bg-white' : dd.ID == nowPhoto}">
                                    <div class="card__photo carousel__slide">
                                        <img :src="dd.Img"/>
                                    </div>
                                    <div class="card__mask d-flex flex-column justify-content-between">
                                        <img v-if="dd.Ranking == '1'" class="mr-2" src="@/assets/images/photography/img_ranking1.png">
                                        <img v-if="dd.Ranking == '2'" class="mr-2" src="@/assets/images/photography/img_ranking2.png">
                                        <img v-if="dd.Ranking == '3'" class="mr-2" src="@/assets/images/photography/img_ranking3.png">
                                        <img v-if="dd.Ranking == '999'" class="mr-2" src="@/assets/images/photography/img_ranking999.png">
                                        
                                        <div class="text-right">
                                            <span class="icon icon--like" @click.stop="ClickNum(dd.ID)" :class="{'active': isPhotoLiked(dd.ID) || dd.ID == photoLiked}"></span>
                                            <small class="font-weight-bold text-white ml-1">{{parseInt(dd.CKNum) + 1}}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
                
                <button class="carousel__prev" @click.prevent="toPrev_cardSmall" v-show="!isPagesLoading" >
                    <svg class="carousel__icon" viewBox="0 0 24 24" role="img">
                        <title>arrowLeft</title>
                        <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
                    </svg>
                </button>
                <button class="carousel__next" @click.prevent="toNext_cardSmall" v-show="!isPagesLoading" >
                    <svg class="carousel__icon" viewBox="0 0 24 24" role="img">
                        <title>arrowRight</title>
                        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
                    </svg>
                </button>
        
            </div>
        </div>

    </div>
</template>

<script>
import PagesLoading from '@/components/PagesLoading.vue';
import _ from "lodash";
import $ from 'jquery';
import 'vue3-carousel/dist/carousel.css';

var ELMAX = [], ELMIN = [];

export default {
    ELMAX, ELMIN,
    components: {
        PagesLoading,
    },
    data() {
        return {
            isPagesLoading: true,
            defaultItem:'全部組別',
            showToggle: false,
            delay:false, 
            Collection: null,
            visible: false,
            index: 0,
            photo: [],
            photoLiked:null,
            photoClicked:null,
            nowPhoto:'',
            isGetNowPhoto:true,

            Collection_cardLarge: [],
            Collection_cardSmall: [],
            carouselName: 'carousel-next',
            lenL: 0,
            lenS: 0,            
            show_cardLarge: 0,
            show_cardSmall: 0,
            minHeightL: 0,
            minHeightS: 0
        }
    },
    setup() {
        document.title = "2021淡水八景4.0-絕景攝影邀請賽";
        document.querySelector('meta[property="og:title"]').setAttribute("content", '2021淡水八景4.0-絕景攝影邀請賽');
        document.querySelector('meta[name="description"]').setAttribute("content", '淡水八景聞名遐邇，趕快拿起你的手機、相機，把這個世紀的美景記錄下來，淡水絕景攝影邀請賽，邀你紀錄屬於我們的美麗記憶。');
        document.querySelector('meta[property="og:description"]').setAttribute("content", '淡水八景聞名遐邇，趕快拿起你的手機、相機，把這個世紀的美景記錄下來，淡水絕景攝影邀請賽，邀你紀錄屬於我們的美麗記憶。');
    },
    created() {
        this.ReadData();
    },
    mounted() {
        window.addEventListener('click', this.handleClickaway);
    },
    beforeUnmount() {
        window.removeEventListener('click', this.handleClickaway);
    },
    updated(){
        this.minHeightL = $( '.card__large' ).height() + 'px';
        this.minHeightS = $( '.card__small' ).height() + 'px';

        if(this.isGetNowPhoto){            
            this.getNowPhoto(this.nowPhoto);
        }

        $(window).scroll(function(){
            if($(window).scrollTop()>=1000){
                $(".goTop").fadeIn(300).click(function() {
                    $('html, body').stop(true, false).animate({
                        scrollTop: 0
                    }, 'slow');
                    return false;
                });                
            } else{
                $(".goTop").fadeOut(300);
            }
        });
    },
    methods:{
        // ============ 載入資料 ============
        ReadData:function(ft=null){
            var FD = new FormData();
            FD.append('mode', 'sct');
            FD.append('data', '{"class":"Collection","lang":"zh-TW"}');

            this.axios({
                method: 'post',
                url: 'RApi',
                data: FD,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then(response => {
                // 大卡片  
                if(ft!=null){ //傳入分類
                    this.isGetNowPhoto=true;
                    ELMAX=response.data.data.filter(v => v.Ranking!='' && v.Teams == ft).sort(function (a, b) {
                        return a.Ranking - b.Ranking;
                    });
                    this.Collection_cardLarge = ELMAX;
                    this.show_cardLarge=0;
                    this.show_cardSmall=0;
                } else{
                    ELMAX=response.data.data.filter(v => v.Ranking!='').sort(function (a, b) {
                        return a.Ranking - b.Ranking;
                    });
                    this.Collection_cardLarge = ELMAX;
                    // 小卡片             
                    ELMIN = _.chunk(ELMAX, 3);
                    this.Collection_cardSmall = ELMIN;
                    this.lenS = ELMIN.length;
                    this.isPagesLoading = false;

                    if(this.$route.params.ID){ // 傳入hashtag( ID )
                        let hashtag = this.$route.params.ID;
                        let hashtagMeta = response.data.data.filter(t => t.ID == hashtag);
                        // 顯示對應作品
                        this.getPhotoId(hashtag);  
                        this.nowPhoto = hashtag;
                        // 換metadata
                        document.title = "2021淡水絕景攝影邀請賽-" + hashtagMeta[0].Name + "/" + hashtagMeta[0].Title;
                        document.querySelector('meta[property="og:title"]').setAttribute("content", "2021淡水絕景攝影邀請賽-" + hashtagMeta[0].Name + "/" + hashtagMeta[0].Title);
                        document.querySelector('meta[property="og:description"]').setAttribute("content", '好想去旅行，但是你錯過了什麼嗎？先來看看絕美的淡水，重新好好規劃吧!');
                        if(this.$route.params.share=='f'){
                            var StrA=hashtagMeta[0].Img.split("/");
                            var Url='';
                            StrA.forEach(function(item,index){if(index<StrA.length-1){Url=Url+item+'/';}});
                            document.querySelector('meta[property="og:image"]').setAttribute("content",Url+'awards/'+StrA[StrA.length-1].split(".")[0]+'_bg.jpg');
                        } else if(this.$route.params.share=='l'){
                            document.querySelector('meta[property="og:image"]').setAttribute("content", hashtagMeta[0].Img);
                        }
                    }
                }
                this.lenL = ELMAX.length; 

                // 小卡片             
                ELMIN = _.chunk(ELMAX, 3);
                this.Collection_cardSmall = ELMIN;
                this.lenS = ELMIN.length; 

                this.getNowPhoto(this.nowPhoto);
                this.isPagesLoading = false;
                this.delay=true;
            })
            .catch(function (error) {
                console.log(error);
            });
        }, 

        // ============ 取得當前照片ID ============
        getNowPhoto(id){
            let nowIndex;
            let findId;
            for (var i = 0; i < ELMIN.length; i++) {
                findId = ELMIN[i].some(function(ee){ 
                    return ee.ID == id 
                });
                if(findId){ nowIndex = i;}
            }
            this.show_cardSmall = nowIndex;
        },

        // ============ 燈箱 lightbox ============
        showImg(index) {
            this.index = index;
            this.visible = true;
            document.body.classList.add("modal-open");
        },
        handleHide() {
            this.visible = false;
            document.body.classList.remove("modal-open");
        },

        // ============ 左右輪播按鈕 ============
        toNext() {
			this.carouselName = 'carousel-next';
			this.show_cardLarge + 1 >= this.lenL ? this.show_cardLarge = 0 : this.show_cardLarge = this.show_cardLarge + 1;
            this.isGetNowPhoto = true;
		},
		toPrev() {
			this.carouselName = 'carousel-prev';
			this.show_cardLarge - 1 < 0 ? this.show_cardLarge = this.lenL - 1 : this.show_cardLarge = this.show_cardLarge - 1;
            this.isGetNowPhoto = true;
		},
        toNext_cardSmall() {
			this.carouselName = 'carousel-next';
            this.show_cardSmall + 1 >= this.lenS ? this.show_cardSmall = 0 : this.show_cardSmall = this.show_cardSmall + 1;
            this.isGetNowPhoto = false;
		},
		toPrev_cardSmall() {
			this.carouselName = 'carousel-prev';
            this.show_cardSmall - 1 < 0 ? this.show_cardSmall = this.lenS - 1 : this.show_cardSmall = this.show_cardSmall - 1;
            this.isGetNowPhoto = false;
		},

        // ============ 點選下方slide照片 ============
        getPhotoId(id){
            if( this.deviceMode=='mobile' ){
                 setTimeout(() => {
                    $('html, body').stop(true, false).animate({
                        scrollTop: $("#"+id).offset().top
                    }, 'slow');
                }, 500)               
            } else {
                let clickedKey = this.Collection_cardLarge.findIndex((e)=>{
                    return e.ID == id
                })
                if( clickedKey > this.show_cardLarge ){
                   this.carouselName = 'carousel-next';
                } else {
                    this.carouselName = 'carousel-prev';
                }
                this.show_cardLarge = clickedKey;
                this.photoClicked = id;
            }
        },

        // ============ 計算按讚數 ============
        ClickNum:function(ID){
            this.photoLiked = ID;

            var ClickNumArr;
            if(this.$cookies.get('ClickNum')==null){this.$cookies.set('ClickNum','0,1');}
            ClickNumArr=this.$cookies.get('ClickNum').split(",");
            if(ClickNumArr.indexOf(ID.toString())==-1){ 
                var FD = new FormData();
                    FD.append('mode', 'count');
                    FD.append('data', '{"id":"'+ID+'","name":"CKNum"}');
                    this.axios({
                        method: 'post',
                        url: 'RApi',
                        data: FD,
                        headers: {'Content-Type': 'multipart/form-data' }
                    })
                ClickNumArr.push(ID.toString());
                this.$cookies.set('ClickNum',ClickNumArr);
            }
        },

        // ============ 按過讚的變紅色 ============
        isPhotoLiked(ID){
            if(this.$cookies.get('ClickNum')==null){this.$cookies.set('ClickNum','0,1');}
            if(this.$cookies.get('ClickNum').split(",").indexOf(ID.toString())!=-1){
                return true;
            }else{
                return false;
            }
        },
    
        // ============ 點擊空白處關閉 ============
        handleClickaway(e) {
            const { clickaway } = this.$refs;
            if(!clickaway.contains(e.target)){
                this.showToggle = false;
            }
        },
    }
}
</script>